/* eslint-disable security/detect-non-literal-require */
import { GetStaticPropsContext } from 'next';
import { useTranslations } from 'next-intl';
import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import TotalBatchReports from '../components/dashboard/RecentBatchReports';
import TotalBookmarks from '../components/dashboard/RecentBookmarks';
import TotalPortfolios from '../components/dashboard/RecentPortfolios';
import TotalReports from '../components/dashboard/RecentReports';
import LinkedInFeed from '../components/elements/LinkedInFeed';
import Layout from '../components/layout/Layout';
import LinkCardRow from '../components/sections/LinkCardRow';
import Tabs from '../components/Tabs';
import useAllPortfolios from '../hooks/useAllPortfolios';
import useBatchReportsHistory from '../hooks/useBatchReportsHistory';
import useBilling from '../hooks/useBilling';
import useOrganisation from '../hooks/useOrganisation';
import { usePlaywright } from '../hooks/usePlaywright';
import useUser from '../hooks/useUser';
import appState from '../lib/appState';
import fetcher from '../lib/utils/fetcher';
import { formatNumber } from '../lib/utils/number-helpers';

export default function Dashboard() {
  const t = useTranslations();
  const [playwrightId, setPlaywrightId] = usePlaywright('dashboard');

  const { user } = useRecoilValue(appState);
  const { userFeatureIsActive } = useUser();
  const { canUserSeeUsage } = useOrganisation();
  const { limits } = useBilling({
    orgId: user?.organisation_id,
  });
  const [totalReports, setTotalReports] = useState(0);
  const [fetchingReports, setFetchingReports] = useState(false);

  const [batchReportsLength, setBatchReportsLength] = useState(0);
  const [fetchingBatch, setFetchingBatch] = useState(false);

  const { portfolios } = useAllPortfolios({ limit: 5, withCompanies: true });

  const { batchReports } = useBatchReportsHistory({
    limit: 5,
  });

  const isPortfolioEnabled = userFeatureIsActive('PORTFOLIO_MANAGEMENT');

  const portfoliosTotal = (
    portfolios?.total === 0 ? '0' : portfolios?.total ?? 0
  ).toString();

  const portfolioTabValue = canUserSeeUsage
    ? `${limits?.portfolios?.spent} / ${limits?.portfolios?.total}`
    : portfoliosTotal;

  useEffect(() => {
    if (user?.id) {
      const body = {
        pagination: { limit: 1, offset: 0 },
        filter_by: [
          {
            conditions: [
              {
                type: 'exact',
                field: 'owner_id',
                value: user?.id,
              },
            ],
          },
        ],
      };
      /**
       * Fetch the total number of reports for the user
       */
      setFetchingReports(true);
      fetcher('/api/reports/list', 'POST', body)
        .then(res => {
          if (res.ok && res.data?.total) {
            setTotalReports(res.data.total);
          }
        })
        .finally(() => {
          setFetchingReports(false);
        });

      /**
       * Fetch the total number of batch reports for the user
       */
      setFetchingBatch(true);
      fetcher('/api/batch-reports/list', 'POST', body)
        .then(res => {
          if (res.ok && res.data?.query_total) {
            setBatchReportsLength(res.data.query_total);
          }
        })
        .finally(() => {
          setFetchingBatch(false);
        });
    }
  }, [user]);

  return (
    <Layout
      title="Dashboard"
      usageClassName="sm:absolute sm:top-0 sm:mb-10"
      usageWrapper={'sm:mb-10'}
    >
      <div className="grid grid-cols-7 gap-x-4 text-primary" {...playwrightId}>
        <div className="col-span-7 md:col-span-5">
          <div className="mb-4">
            <p className="h-6 text-base md:-mt-6">{t('welcome_back')}</p>

            <div className="flex justify-between items-center">
              <h1 className="text-2xl font-semibold truncate max-w-[90%]">
                {user && user.full_name}{' '}
                {user?.organisation_name && `(${user.organisation_name})`}
              </h1>
              {/* <p className="font-bold min-w-[100px] ml-4 md:ml-12">
                {t('last_7_days')}
              </p> */}
            </div>
          </div>

          <Tabs>
            <Tabs.Headers
              className="w-full"
              playwrightId={setPlaywrightId('tabs')}
            >
              <Tabs.Header
                label={t('total_reports')}
                value={
                  !fetchingReports && totalReports === 0
                    ? '0'
                    : formatNumber(totalReports)
                }
                link={{
                  href: '/reports',
                  label: t('view_all'),
                }}
              />
              <Tabs.Header
                label={t('batch_reports')}
                value={
                  !fetchingBatch && batchReportsLength === 0
                    ? '0'
                    : formatNumber(batchReportsLength)
                }
                link={{
                  href: '/batch-reports',
                  label: t('view_all'),
                }}
              />
              <Tabs.Header
                label={
                  isPortfolioEnabled ? t('portfolios') : t('bookmarked_reports')
                }
                value={
                  isPortfolioEnabled
                    ? portfolioTabValue
                    : user?.bookmarked_reports?.length || '0'
                }
                link={
                  isPortfolioEnabled
                    ? {
                        href: '/portfolios',
                        label: t('view_all'),
                      }
                    : {
                        href: '/reports#bookmarked_reports',
                        label: t('view_all'),
                      }
                }
              />
            </Tabs.Headers>
            <Tabs.Panels>
              <Tabs.Panel>
                <TotalReports />
              </Tabs.Panel>

              <Tabs.Panel>
                <TotalBatchReports
                  /** @TODO replace swr query with ne List endpoint */
                  batchReports={batchReports}
                  totalBatchReports={batchReportsLength}
                />
              </Tabs.Panel>

              <Tabs.Panel>
                {isPortfolioEnabled ? (
                  <TotalPortfolios allPortfolios={portfolios?.portfolios} />
                ) : (
                  <TotalBookmarks />
                )}
              </Tabs.Panel>
            </Tabs.Panels>
          </Tabs>
        </div>

        <div className="hidden md:col-span-2 md:block">
          <LinkedInFeed
            className="mt-12 h-[398px] overflow-y-scroll w-full max-w-sm"
            playwrightId={setPlaywrightId('twitter')}
          />
        </div>
      </div>
      {/* link cards */}
      <LinkCardRow />

      {/* <div className="mx-auto mt-8 md:hidden mb-12">
        <h3 className="font-semibold">{t('updates')}</h3>
        <LinkedInFeed/> 
      </div> */}
    </Layout>
  );
}

export async function getStaticProps({ locale }: GetStaticPropsContext) {
  return {
    props: {
      messages: {
        // You can get the messages from anywhere you like, but the recommended
        // pattern is to put them in JSON files separated by language and read
        // the desired one based on the `locale` received from Next.js.
        ...require(`../messages/${locale}/index.${locale}.json`),
        ...require(`../messages/${locale}/general.${locale}.json`),
        ...require(`../messages/${locale}/errors.${locale}.json`),
        ...require(`../messages/${locale}/toasts.${locale}.json`),
      },
    },
  };
}
