import { useTranslations } from 'next-intl';
import React from 'react';
import { useRecoilValue } from 'recoil';

import { usePlaywright } from '../../hooks/usePlaywright';
import appState from '../../lib/appState';
import { getSummaryProperty } from '../../lib/utils/portfolio-helpers';
import { convertNumberToPercentage } from '../../lib/utils/text-helpers';
import { IPortfolio } from '../../types/portfolio';
import { TableHeadersType } from '../../types/table';
import Table from '../table/Table';

const RecentPortfolios = ({
  allPortfolios,
}: {
  allPortfolios?: IPortfolio[] | null;
}) => {
  const t = useTranslations();
  const [playwrightId, setPlaywrightId] = usePlaywright(
    'dashboard.total-batch-reports'
  );

  const { user } = useRecoilValue(appState);

  const ReportTableHeaders: TableHeadersType[] = [
    {
      name: t('portfolios'),
      selector: 'name', // replace with getReportName wheen created_at added to response
      align: 'left',
      width: 'w-[50%]',
      contentClassName: 'truncate !max-w-xxs sm:max-w-xs lg:max-w-sm',
      rowTitle: row => row?.name,
    },
    {
      name: t('tabs.portfolios.table.rows.sme_zscore'),
      selector: getSummaryProperty('sme_z_score'),
      align: 'center',
      width: 'w-[10%]',
    },
    {
      name: t('tabs.portfolios.table.rows.pd'),
      selector: getSummaryProperty('probability_of_default_1_year', val =>
        convertNumberToPercentage(Number(val))
      ),
      align: 'center',
      width: 'w-[10%]',
    },
    {
      name: t('tabs.portfolios.table.rows.bre'),
      selector: getSummaryProperty('bond_rating_equivalent'),
      align: 'center',
      width: 'w-[10%]',
    },
    {
      name: t('tabs.portfolios.table.rows.companies'),
      selector: getSummaryProperty('number_of_companies'),
      align: 'center',
      width: 'w-[10%]',
    },
    {
      name: '',
      selector: 'MENU',
      align: 'center',
      width: 'w-[5%]',
      rowTitle: 'Menu',
    },
  ];

  return (
    <div className="flex flex-col space-y-10 pt-4" {...playwrightId}>
      <span className="shadow">
        <Table playwrightId={setPlaywrightId('recent-portfolios-table')}>
          <Table.Body
            tableId="REPORTS"
            tableName={t('no_data_recent_reports')}
            headers={ReportTableHeaders}
            data={allPortfolios ?? []}
            total={user?.reports?.length}
            rowLimit={5}
            isLoading={false}
            rowLink={(row: { id: string }) => `/portfolios/${row.id}?from=/`}
            fillEmptyRows
          />
        </Table>
      </span>
    </div>
  );
};

export default RecentPortfolios;
