import { MethodTypes } from '../api-handler/api-handler';
import { defaultHeaders } from '../api-handler/headers';

/**
 * @important This wrapper should only be used for server-side requests.
 * It contains headers that should not be exposed to the client. Use
 * fetcher for client-side requests.
 */

export const fetchWrapper = async (
  route: string,
  {
    method,
    headers,
    body,
  }: {
    method: MethodTypes;
    headers?: Record<string, string>;
    body?: ReadableStream | string | URLSearchParams;
  }
) => {
  return await fetch(route, {
    method,
    headers: {
      ...defaultHeaders,
      ...headers,
    },
    body,
  });
};
