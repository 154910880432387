import fetcher from '../../../lib/utils/fetcher';
import { ICompany } from '../../../types/companies';
import { IPortfolio } from '../../../types/portfolio';
import { Option } from '../../elements/MultiSelect';

// extracted for testing purposes
export const handleSetPortfolios = ({
  option,
  selectedPortfolios,
}: {
  option: Option;
  selectedPortfolios: Option[];
}) => {
  return selectedPortfolios.map(pf => {
    if (pf.value === option.value) {
      pf.selected = !pf.selected;
    }

    return pf;
  });
};

export const handleSelectedCompanies = ({
  checked,
  row,
  selectedCompanies,
}: {
  checked: boolean;
  row: ICompany;
  selectedCompanies: ICompany[];
}) => {
  let newRows: ICompany[] = [];
  const index = selectedCompanies.findIndex(comp => comp.id === row.id);

  if (checked && index === -1) {
    newRows = [...selectedCompanies, row];
  } else {
    delete newRows[index];
  }

  return newRows.filter(Boolean);
};

export const resetSelectedPortfolios = ({
  portfolios,
}: {
  portfolios: Array<IPortfolio & { selected?: boolean }>;
}) => {
  if (portfolios?.length) {
    const mappedPf: Option[] = portfolios.map(pf => {
      return {
        label: pf.name,
        value: pf.id,
        selected: pf.selected,
      };
    });

    return mappedPf;
  } else {
    return [];
  }
};

export const addCompanyToPortfolio = async ({
  portfolioId,
  companyId,
}: {
  portfolioId: string;
  companyId: string;
}) => {
  return await fetch(
    `/api/portfolios/${portfolioId}/companies?company_id=${companyId}`,
    {
      method: 'POST',
    }
  );
};

export const removeCompanyFromPortfolio = async ({
  portfolioId,
  companyId,
}: {
  portfolioId: string;
  companyId: string;
}) => {
  return await fetch(
    `/api/portfolios/${portfolioId}/companies?company_id=${companyId}`,
    {
      method: 'DELETE',
    }
  );
};

export const addOrRemoveCompanyFromPortfolio = async ({
  portfolioId,
  companyId,
  addToPortfolio,
}: {
  portfolioId: string;
  companyId: string;
  addToPortfolio: boolean;
}) => {
  return await fetch(
    `/api/portfolios/${portfolioId}/companies?company_id=${companyId}`,
    {
      method: addToPortfolio ? 'POST' : 'DELETE',
    }
  );
};

export const togglePortfolioCheck = async (
  portfolios: Option[],
  companyUUID: string | null
): Promise<Array<Response & { portfolio?: Option }>> => {
  if (!companyUUID) {
    return [];
  }
  return await Promise.all(
    portfolios.map(async pf => {
      const result = await addOrRemoveCompanyFromPortfolio({
        portfolioId: pf.value,
        companyId: companyUUID,
        addToPortfolio: pf?.selected || false,
      });

      // adding the option onto the response body because we can't access the JSON body. Bit hacky but we need the res.ok and the portfolio data for the toast
      // @ts-ignore
      result.portfolio = pf;

      return result;
    })
  );
};
