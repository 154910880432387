import { type } from 'os';

import {
  ILoanData,
  ILoanSingle,
  LoanDataReportObject,
  LoanDataUploadHeaders,
  PortfolioLoanCsvReport,
  PortfolioLoanEntity,
  SecurityType,
} from '../../types/portfolio';
import { isBoolean } from './types.utils';

export const FormatFields: Record<string, LoanDataUploadHeaders[]> = {
  strings: [
    'company_id',
    'iso_code',
    'loan_id',
    'currency',
    'security_type',
    'security_valuation_date',
    'start_drawdown_date',
    'maturity_date',
    'balance_date',
  ],
  boolean: ['credit_insurance'],
  numbers: [
    'outstanding_balance',
    'commitment_limit',
    'security_value',
    'interest_rate',
    'days_past_due',
  ],
};

export const isCsvDataBatchLoan = (
  data: unknown
): data is PortfolioLoanCsvReport => {
  if (
    data &&
    typeof data === 'object' &&
    'company_id' in data &&
    'iso_code' in data &&
    'loan_id' in data &&
    'outstanding_balance' in data &&
    'commitment_limit' in data &&
    'security_type' in data &&
    'security_value' in data &&
    'security_valuation_date' in data &&
    'interest_rate' in data
  ) {
    return true;
  }

  return false;
};

export const isCsvDataSingleLoan = (
  data: unknown
): data is PortfolioLoanCsvReport => {
  if (
    data &&
    typeof data === 'object' &&
    'loan_id' in data &&
    'outstanding_balance' in data &&
    'commitment_limit' in data &&
    'security_type' in data &&
    'security_value' in data &&
    'security_valuation_date' in data &&
    'interest_rate' in data
  ) {
    return true;
  }

  return false;
};

export const makeUploadPortfolioLoanReqBody = (
  reportObject: LoanDataReportObject
): PortfolioLoanEntity => {
  const handleString = (value: unknown): string => {
    if (
      value === undefined ||
      value === null ||
      Number(value) === 0 ||
      isBoolean(value) ||
      (typeof value === 'string' && value.toLowerCase() === 'null')
    ) {
      return '';
    }

    return value.toString();
  };

  const handleNumber = (value: unknown) => {
    if (
      value === null ||
      value === '' ||
      (typeof value === 'string' && value.toLowerCase() === 'null')
    ) {
      return null;
    }

    if (Number(value) === 0) {
      return 0;
    }

    // remove all non-number characters except a - sign at the start (for negatives)
    const cleanNumber =
      typeof value === 'string' &&
      value?.replace?.(/(?!^-)[^\de+\-eE.]+/gi, '');

    // if null will be cleaned to an empty string
    // if there are no numbers in the string it will be cleaned to an empty string
    // in which case return null
    if (cleanNumber === '' || typeof cleanNumber === 'undefined') {
      return null;
    }

    // if the number is 0 return 0
    if (Number(cleanNumber) === 0) {
      return 0;
    }

    // if the number is not 0 return the number
    return Number(cleanNumber);
  };

  const handleBoolean = (value: unknown) => {
    if (value === null || value === '' || typeof value === 'undefined') {
      return null;
    }

    if (typeof value === 'string') {
      return value.toLowerCase() === 'true';
    }

    return value;
  };

  const entity: PortfolioLoanEntity = Object.entries(reportObject).reduce(
    (acc, keyValue, i) => {
      const key = keyValue[0] as LoanDataUploadHeaders;
      const values = keyValue[1] as Array<unknown>;
      const [value] = values.map((value, j) => {
        if (FormatFields.boolean.includes(key)) {
          return handleBoolean(value);
        }
        if (FormatFields.strings.includes(key)) {
          return handleString(value);
        }
        if (FormatFields.numbers.includes(key)) {
          return handleNumber(value);
        }
        return value;
      });

      return {
        ...acc,
        [key]: value,
      };
    },
    {} as PortfolioLoanEntity
  );

  return entity;
};

export function isYesOrTrue(value: unknown): boolean {
  if (typeof value === 'string') {
    return (
      value.trim().toLowerCase() === 'yes' ||
      value.trim().toLowerCase() === 'true'
    );
  }

  if (typeof value === 'boolean') {
    return value;
  }

  return false;
}

function parseValidFloat(value: string): number | null {
  const num = parseFloat(value);
  return isNaN(num) ? null : num;
}

function parseValidInt(value: string): number | null {
  const num = parseInt(value);
  return isNaN(num) ? null : num;
}

export const makeEntitiesForBatchLoan = (
  csvData: PortfolioLoanCsvReport
): ILoanData[] => {
  const output: ILoanData[] = [];

  for (let i = 0; i < csvData.company_id.length; i++) {
    output.push({
      company_id: csvData.company_id[i],
      loan_id: csvData.loan_id[i],
      currency: csvData.currency[i],
      outstanding_balance: parseValidFloat(csvData.outstanding_balance[i]),
      commitment_limit: parseValidFloat(csvData.commitment_limit[i]),
      security_type: csvData.security_type[i].toUpperCase() as SecurityType,
      security_value: csvData.security_value[i]
        ? parseValidFloat(csvData.security_value[i])
        : null,
      security_valuation_date: csvData.security_valuation_date[i],
      maturity_date: csvData.maturity_date[i],
      interest_rate: csvData.interest_rate[i]
        ? parseValidFloat(csvData.interest_rate[i])
        : null,
      days_past_due: csvData.days_past_due[i]
        ? parseValidInt(csvData.days_past_due[i])
        : null,
      credit_insurance: isYesOrTrue(csvData.credit_insurance[i]),
      balance_date: csvData.balance_date[i],
      iso_code: csvData.iso_code[i],
      start_drawdown_date: csvData.start_drawdown_date[i],
    });
  }

  return output;
};

export const makeEntitiesForCompanyLoans = (
  csvData: PortfolioLoanCsvReport
): ILoanSingle[] => {
  const output: ILoanSingle[] = [];

  for (let i = 0; i < csvData.loan_id.length; i++) {
    output.push({
      loan_id: csvData.loan_id[i],
      currency: csvData.currency[i],
      outstanding_balance: parseValidFloat(csvData.outstanding_balance[i]),
      commitment_limit: parseValidFloat(csvData.commitment_limit[i]),
      security_type: csvData.security_type[i].toUpperCase() as SecurityType,
      security_value: csvData.security_value[i]
        ? parseValidFloat(csvData.security_value[i])
        : null,
      security_valuation_date: csvData.security_valuation_date[i],
      maturity_date: csvData.maturity_date[i],
      interest_rate: csvData.interest_rate[i]
        ? parseValidFloat(csvData.interest_rate[i])
        : null,
      days_past_due: csvData.days_past_due[i]
        ? parseValidInt(csvData.days_past_due[i])
        : null,
      credit_insurance: isYesOrTrue(csvData.credit_insurance[i]),
      balance_date: csvData.balance_date[i],
      start_drawdown_date: csvData.start_drawdown_date[i],
    });
  }

  return output;
};
