import { useTranslations } from 'next-intl';
import React from 'react';
import ReactTimeago from 'react-timeago';

import { usePlaywright } from '../../hooks/usePlaywright';
import { BatchReportResponse } from '../../types/batch-reports';
import { TableHeadersType } from '../../types/table';
import Table from '../table/Table';

const RecentBatchReports = ({
  batchReports,
  totalBatchReports,
}: {
  batchReports: BatchReportResponse[];
  totalBatchReports: number;
}) => {
  const t = useTranslations();
  const [playwrightId, setPlaywrightId] = usePlaywright(
    'dashboard.total-batch-reports'
  );

  const ReportTableHeaders: TableHeadersType[] = [
    {
      name: t('company_name'),
      selector: 'name',
      align: 'left',
      width: 'w-[70%]',
      contentClassName: 'truncate max-w-[400px]',
      rowTitle: row => row?.name,
    },
    {
      name: t('successful'),
      selector: 'success_reports',
      align: 'center',
      width: 'w-[15%]',
    },
    {
      name: t('total_run'),
      selector: 'total_reports',
      align: 'center',
      width: 'w-[15%]',
    },
    {
      name: t('created'),
      selector: (row: { created_at: string }) => (
        // @ts-ignore - UPGRADE
        <ReactTimeago date={row.created_at} />
      ),
      align: 'center',
      width: 'w-[10%] pr-4',
    },
  ];

  return (
    <div className="flex flex-col space-y-10 pt-4" {...playwrightId}>
      <span className="shadow">
        <Table playwrightId={setPlaywrightId('batch-reports-table')}>
          <Table.Body
            tableId="REPORTS"
            tableName={t('no_data_recent_reports')}
            headers={ReportTableHeaders}
            data={batchReports}
            total={totalBatchReports}
            rowLimit={5}
            isLoading={false}
            rowLink={(row: { id: string }) => `/batch-reports/${row.id}?from=/`}
            fillEmptyRows
          />
        </Table>
      </span>
    </div>
  );
};

export default RecentBatchReports;
