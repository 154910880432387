import React from 'react';

interface WFLogoProps {
  className?: string;
  stroke?: string;
}

const WFLogo = ({ className = 'text-white' }: WFLogoProps) => {
  return (
    <svg
      width="220"
      height="220"
      className={className}
      viewBox="0 0 220 220"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13819_39639)">
        <g opacity="0.8">
          <path
            d="M15.5016 110.3H-0.398438V154.2H15.5016V110.3Z"
            fill="white"
          />
        </g>
        <g opacity="0.8">
          <path d="M73.0016 66.5H57.1016V110.4H73.0016V66.5Z" fill="white" />
        </g>
        <path d="M57.1 66.5L15.5 110.3V154.2L57.1 110.3V66.5Z" fill="white" />
        <g opacity="0.8">
          <path d="M89.1031 110.3H73.2031V154.2H89.1031V110.3Z" fill="white" />
        </g>
        <g opacity="0.8">
          <path d="M146.603 66.5H130.703V110.4H146.603V66.5Z" fill="white" />
        </g>
        <path
          d="M130.702 66.5L89.1016 110.3V154.2L130.702 110.3V66.5Z"
          fill="white"
        />
        <g opacity="0.8">
          <path d="M162.502 110.3H146.602V154.2H162.502V110.3Z" fill="white" />
        </g>
        <g opacity="0.8">
          <path d="M220.103 66.5H204.203V110.4H220.103V66.5Z" fill="white" />
        </g>
        <path
          d="M204.202 66.5L162.602 110.3V154.2L204.202 110.3V66.5Z"
          fill="white"
        />
      </g>
      <rect
        x="0.05"
        y="0.05"
        width="219.9"
        height="219.9"
        stroke="black"
        strokeOpacity="0.01"
        strokeWidth="0.1"
      />
      <defs>
        <clipPath id="clip0_13819_39639">
          <rect
            width="220"
            height="87"
            fill="white"
            transform="translate(0 67)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default WFLogo;
