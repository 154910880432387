import React, { FC } from 'react';
import LinkCard from '../cards/LinkCard';
import {
  DocumentDuplicateIcon,
  ChipIcon,
  HandIcon,
} from '@heroicons/react/outline';
import { PlaywrightProps } from '../../types/global';
import WFLogo from '../svgs/WFLogo';
import { usePlaywright } from '../../hooks/usePlaywright';
import { useTranslations } from 'next-intl';

interface LinkCardRowProps extends PlaywrightProps {}

const LinkCardRow: FC<LinkCardRowProps> = ({ playwrightId }) => {
  const t = useTranslations();
  const [id, setPlaywrightId] = usePlaywright(playwrightId || 'link-card-row');
  return (
    <div
      className="grid max-w-3xl grid-cols-2 gap-2 mx-auto mt-2 md:grid-cols-4 md:max-w-none md:mr-auto "
      {...id}
    >
      <LinkCard
        className="mx-auto"
        icon={<WFLogo className="w-6 h-6 text-white" />}
        iconColor="bg-new-highlight"
        header={t('automated_report_header')}
        description={t('automated_report_description')}
        linkTo="/sme-calculator"
        playwrightId={setPlaywrightId('automated-report')}
      />
      <LinkCard
        className="mx-auto"
        icon={
          <DocumentDuplicateIcon className='className="w-6 h-6 text-white' />
        }
        iconColor="bg-highlight-3"
        header={t('batch_reports_header')}
        description={t('batch_reports_description')}
        linkTo="/batch-reports"
        playwrightId={setPlaywrightId('batch_reports')}
      />
      <LinkCard
        className="mx-auto"
        icon={<ChipIcon className='className="w-6 h-6 text-white ' />}
        iconColor="bg-highlight-4"
        header={t('api_docs_header')}
        description={t('api_docs_description')}
        linkTo="/api-documentation"
        playwrightId={setPlaywrightId('api_docs')}
      />
      {/* Temporary card until SME Prospector is implemented and online */}
      <LinkCard
        className="mx-auto"
        icon={<HandIcon className='className="w-6 h-6 text-white ' />}
        iconColor="bg-highlight-2"
        header={t('support_header')}
        description={t('support_description')}
        linkTo="/support"
        playwrightId={setPlaywrightId('support')}
      />
    </div>
  );
};

export default LinkCardRow;
