import { useTranslations } from 'next-intl';
import React from 'react';
import ReactTimeago from 'react-timeago';
import { useRecoilValue } from 'recoil';

import { usePlaywright } from '../../hooks/usePlaywright';
import appState from '../../lib/appState';
import { createReportTitle } from '../../lib/utils/text-helpers';
import { TableHeadersType } from '../../types/table';
import Table from '../table/Table';

const RecentReports = () => {
  const t = useTranslations();
  const [playwrightId, setPlaywrightId] = usePlaywright(
    'dashboard.total-reports'
  );

  const { user } = useRecoilValue(appState);

  const reports: { created_at: string }[] = user?.reports?.slice();
  const sortedReports =
    reports &&
    reports
      .sort((a, b) => {
        const bDate = new Date(b.created_at).getTime();
        const aDate = new Date(a.created_at).getTime();
        return bDate - aDate;
      })
      .slice(0, 5);

  const getReportName = (row: { company_name: string; created_at: string }) =>
    createReportTitle(row.company_name || t('unnamed_company'), row.created_at);

  const ReportTableHeaders: TableHeadersType[] = [
    {
      name: t('company_name'),
      selector: getReportName,
      align: 'left',
      width: 'w-[70%]',
      contentClassName: 'truncate max-w-[400px]',
      rowTitle: getReportName,
    },
    {
      name: t('sme_z-score'),
      selector: 'sme_z_score',
      align: 'center',
      width: 'w-[10%]',
    },
    {
      name: t('bre'),
      selector: 'bond_rating_equivalent',
      align: 'center',
      width: 'w-[10%]',
    },
    {
      name: t('created'),
      selector: (row: { created_at: string }) => (
        // @ts-ignore - UPGRADE
        <ReactTimeago date={row.created_at} />
      ),
      align: 'center',
      width: 'w-[10%] pr-4',
    },
  ];

  return (
    <div className="flex flex-col space-y-10 pt-4 " {...playwrightId}>
      <span className="shadow">
        <Table playwrightId={setPlaywrightId('recent-reports-table')}>
          <Table.Body
            tableId="REPORTS"
            tableName={t('no_data_recent_reports')}
            headers={ReportTableHeaders}
            data={sortedReports}
            total={user?.reports?.length}
            rowLimit={5}
            isLoading={false}
            rowLink={(row: { id: string }) => `/report/${row.id}?from=/`}
            fillEmptyRows
          />
        </Table>
      </span>
    </div>
  );
};

export default RecentReports;
