import React from 'react';

import { usePlaywright } from '../../hooks/usePlaywright';
import { TabHeaderContainerProps, TabHeaderProps } from './tabs';

const isReactChild = (child: React.ReactNode): boolean => {
  return (
    React.isValidElement(child) &&
    typeof child !== 'string' &&
    typeof child !== 'number' &&
    typeof child !== 'boolean'
  );
};

const TabHeaderContainer: React.FC<TabHeaderContainerProps> = ({
  className,
  children,
  playwrightId,
  disabled,
}) => {
  const [id] = usePlaywright(playwrightId || 'tabs');
  if (!children) return null;

  const tabs = React.Children.toArray(children);
  const totalTabHeaders = tabs.filter((child: any) => {
    const name = child?.type?.displayName || '';
    return name === 'TabHeader';
  }).length;

  return (
    <div
      className={`flex flex-col shadow sm:flex-row ${className ?? ''}`}
      {...id}
    >
      {React.Children.map(tabs, (child: any, index) => {
        if (!isReactChild(child)) return child;
        const name = child?.type?.displayName || '';

        if (React.isValidElement(child) && name === 'TabHeader') {
          return React.cloneElement(
            child as React.ReactElement<TabHeaderProps>,
            { index, totalTabHeaders, playwrightId, disabled }
          );
        }

        return child;
      })}
    </div>
  );
};

export default TabHeaderContainer;
