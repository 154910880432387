/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-case-declarations */
/* eslint-disable sonarjs/no-duplicate-string */
import dayjs from 'dayjs';

import { AlertValueOption } from '../../components/elements/NumberInputWithSwitch';
import {
  AlertOptionKeyTypes,
  AlertTriggerSettingsType,
  ILoanData,
  IPortfolioCreate,
  IPortfolioForm,
  IPortfolioUpdate,
  WatchlistThresholds,
} from '../../types/portfolio';
import fetcher from './fetcher';

interface BodyOptions {
  /** is the form for a new or existing portfolio */
  action?: 'create' | 'update';
  /** does the user want or have the Portfolio Alerts enabled (eg sme_zscore_start) enabled */
  alertsEnabled: boolean;
  /** remove undefined values from body? */
  clean?: boolean;
}

export const THRESHOLD_RED = 'rgba(232,136,136,0.5)';
export const THRESHOLD_AMBER = 'rgb(254,206,142,0.5)';
export const THRESHOLD_GREEN = 'rgba(152,230,152,0.5)';

export const DEFAULT_RED_LIMIT = 150;
export const DEFAULT_AMBER_LIMIT = 250;

export const isValidNumber = (value?: number | string): boolean => {
  if (
    value === undefined ||
    value === null ||
    value === '' ||
    Number.isNaN(Number(value))
  ) {
    return false;
  }

  return true;
};

export const isPortfolioLoan = (csvData: unknown): csvData is ILoanData => {
  if (csvData && typeof csvData === 'object' && 'loan_id' in csvData) {
    return true;
  }
  return false;
};

const valueIsZeroOrPositive = (value?: number | string) => {
  return isValidNumber(value) ? Number(value) >= 0 : false;
};

export const makePortfolioBody = (
  props: IPortfolioForm,
  { action, alertsEnabled, clean }: BodyOptions
) => {
  const { alert_zme_from_start, alert_pd_from_start } = props;

  const alertSettings: AlertTriggerSettingsType | {} = {
    ...(valueIsZeroOrPositive(alert_zme_from_start?.value) && {
      sme_z_score: convertAlertSettingForBackend(alert_zme_from_start),
    }),
    ...(valueIsZeroOrPositive(alert_pd_from_start?.value) && {
      probability_of_default_1_year:
        convertAlertSettingForBackend(alert_pd_from_start),
    }),
  };

  const body: IPortfolioCreate | IPortfolioUpdate = {
    name: props.name,
    ...(action === 'create' && {
      start_date: dayjs(props.start_date).format('YYYY-MM-DD'),
    }),
    // backend can't reset end_date back to undefined as null values ignored and empty string rejected
    end_date: props.end_date
      ? dayjs(props.end_date).format('YYYY-MM-DD')
      : null,
    frequency: props.frequency,
    is_monitoring_paused: props.is_monitoring_paused,
    is_alerts_paused: !alertsEnabled,
    watchlist_thresholds: props.watchlist_thresholds,
    ...(alertsEnabled && {
      alert_settings: {
        alert_trigger: alertSettings,
      },
    }),
  };

  if (clean) {
    // remove undefined values from body
    // tested following method in https://jsbench.me,
    // is 30% faster than mapping through the body
    return JSON.parse(JSON.stringify(body));
  }

  return body;
};

export const filterUsers =
  <T extends { id: string }>(selectedUsers: T[], userId: string) =>
  // filter
  (orgUser: T) => {
    const selected = selectedUsers.map(u => u.id).includes(orgUser.id);

    if (selected || orgUser.id === userId) {
      return false;
    }

    return true;
  };

export const validateFrequency = (freq: number): 1 | 3 | 6 | 12 => {
  switch (freq) {
    case 1:
    case 3:
    case 6:
    case 12:
      return freq;

    default:
      return 1;
  }
};

export const convertAlertSettingForBackend = (
  alertSetting?: AlertValueOption
) => {
  if (Number.isFinite(alertSetting?.value) && alertSetting?.type) {
    return {
      value: alertSetting.value,
      type: alertSetting.type === 'percentage' ? 'relative' : 'absolute',
    };
  }
  return {};
};

export const alertSettingValuesKeys: AlertOptionKeyTypes[] = [
  'alert_zme_from_start',
  'alert_pd_from_start',
  'alert_zme_from_end',
  'alert_pd_from_end',
  'other_alerts_bre',
];
export const portfolioPropsHasAlertValues = (formData: IPortfolioForm) =>
  alertSettingValuesKeys.some(
    key => typeof formData?.[key] !== 'string' && formData?.[key]?.value
  );

export const defaultAlert: AlertValueOption = {
  type: 'percentage',
  value: undefined,
};

export const defaultValues = {
  alert_zme_from_start: defaultAlert,
  alert_pd_from_start: defaultAlert,
  alert_zme_from_end: defaultAlert,
  alert_pd_from_end: defaultAlert,
};

export const getSummaryProperty =
  (
    property: string,
    modifier?: (property: string | number) => string | number
  ) =>
  (row: { summary: Record<string, string> }) => {
    if (row?.summary?.[property]) {
      return modifier ? modifier(row.summary[property]) : row.summary[property];
    }
    return '';
  };
export const allowAddToPortfolio = (data: any) => {
  return !!data?.company_uuid || data?.source === 'CUSTOMER';
};

/**
 * Extracted for testing purposes as its easier to mock this way
 * @param data
 */
export const createPortfolio = async (data?: object) =>
  await fetcher(`/api/portfolios`, 'POST', data);

export const convertFromWatchlistChart = (
  lower: number,
  upper: number
): WatchlistThresholds => {
  const isRed = lower === 999; // implies upper === 1000
  const isRedAmber = upper === 1000; // implies lower <= 999
  const isAmber = lower === 0 && upper === 1000;
  const isAmberGreen = lower === 0;
  const isGreen = lower === 0 && upper === 0;
  const isRedGreen = upper === lower + 1;

  // The order is important here
  switch (true) {
    case isGreen:
      return [
        {
          name: 'Green',
          range: [0, 1000],
        },
      ];
    case isAmber:
      return [
        {
          name: 'Amber',
          range: [0, 1000],
        },
      ];
    case isRed:
      return [
        {
          name: 'Red',
          range: [0, 1000],
        },
      ];
    case isRedAmber:
      return [
        {
          name: 'Red',
          range: [0, lower],
        },
        {
          name: 'Amber',
          range: [lower + 1, 1000],
        },
      ];

    case isAmberGreen:
      return [
        {
          name: 'Amber',
          range: [0, upper],
        },
        {
          name: 'Green',
          range: [upper + 1, 1000],
        },
      ];
    case upper === 999: // soi we don't end up with [1001, 1000]
      return [
        {
          name: 'Red',
          range: [0, lower],
        },
        {
          name: 'Amber',
          range: [lower + 1, 999],
        },
        {
          name: 'Green',
          range: [1000, 1000],
        },
      ];
    case isRedGreen:
      return [
        {
          name: 'Red',
          range: [0, lower],
        },
        {
          name: 'Green',
          range: [upper, 1000],
        },
      ];
    default:
      return [
        {
          name: 'Red',
          range: [0, lower],
        },
        {
          name: 'Amber',
          range: [lower + 1, upper],
        },
        {
          name: 'Green',
          range: [upper + 1, 1000],
        },
      ];
  }
};

export const convertFromWatchlistSettings = (
  value: unknown,
  defaultTuple = [0, 0]
): number[] => {
  if (Array.isArray(value) && value[0] && value[1]) {
    switch (value.length) {
      case 1:
        if (value[0].name === 'Red') {
          return [1000, 1000];
        }

        if (value[0].name === 'Amber') {
          return [0, 1000];
        }

        if (value[0].name === 'Green') {
          return [0, 0];
        }

        break;
      case 2:
        // is red-amber
        const isRedAmber = value[0].name === 'Red' && value[1].name === 'Amber';
        // is amber-green
        const isAmberGreen =
          value[0].name === 'Amber' && value[1].name === 'Green';
        // is red-green
        const isRedGreen = value[0].name === 'Red' && value[1].name === 'Green';

        if (isRedAmber) {
          const range1 = value[0].range[1];
          const range2 = value[1].range[1];

          return [range1, range2];
        }

        if (isAmberGreen) {
          const range1 = 0;
          const range2 = value[0].range[1];

          return [range1, range2];
        }

        if (isRedGreen) {
          const range1 = value[0].range[1];
          const range2 = value[1].range[0];

          return [range1, range2];
        }
        break;
      default:
        if (
          typeof value[0].range[1] === 'number' &&
          typeof value[1].range[1] === 'number'
        ) {
          const range1 = value[0].range[1];
          const range2 = value[1].range[1];

          return [range1, range2];
        }
        break;
    }
  }

  return defaultTuple;
};

export function isValidThresholdItem(item: unknown) {
  return (
    typeof item === 'object' &&
    item !== null &&
    'name' in item &&
    typeof item['name'] === 'string' &&
    ['Red', 'Amber', 'Green'].includes(item['name']) &&
    'range' in item &&
    Array.isArray(item.range) &&
    item.range.length === 2 &&
    item.range.every(v => typeof v === 'number')
  );
}

export const isWatchlistThresholds = (
  value: unknown
): value is WatchlistThresholds => {
  if (Array.isArray(value) && value.every(isValidThresholdItem)) {
    return true;
  }
  return false;
};
