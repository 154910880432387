import React, { useContext } from 'react';

import TabContext from './TabContext';

const TabPanelContainer: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { activeTab } = useContext(TabContext);
  if (!children) return null;
  const tabs = React.Children.toArray(children);
  return <div>{tabs.filter((_, i) => activeTab === i)}</div>;
};

export default TabPanelContainer;
