import { useContext, useEffect } from 'react';

import { usePlaywright } from '../../hooks/usePlaywright';
import TabButton from './TabButton';
import TabContext from './TabContext';
import { TabHeaderProps } from './tabs';

const TabHeader: React.VFC<TabHeaderProps> = ({
  label,
  value,
  index = 0,
  totalTabHeaders,
  playwrightId,
  link,
  name,
  className,
  disabled,
}) => {
  const { activeTab, setActiveTab } = useContext(TabContext);
  const [id] = usePlaywright(playwrightId);

  /**
   * We can set the active using the URL hash
   * eg http://localhost:3000/reports#batch_reports will set the active tab to the batch reports tab,
   * but only if the tab is rendered on the page and the hash matches the name prop
   */
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location.hash.startsWith(`#${name}`)
    ) {
      setActiveTab(index);
    }
  }, []);
  return (
    <TabButton
      label={label}
      value={value}
      selected={activeTab === index}
      index={index}
      totalTabHeaders={totalTabHeaders}
      onClick={() => setActiveTab(index)}
      playwrightId={id}
      link={link}
      className={className}
      disabled={disabled}
    />
  );
};

TabHeader.displayName = 'TabHeader';

export default TabHeader;
