import config from '../../config';
import { ApiHandler, HandlerReturn } from '../../types/http';
import { errorsBySourceType, makeErrorResponse } from '../utils/error-handling';
import { fetchWrapper } from '../utils/fetchWrapper';
import {
  makeApiHandlerResponseFailure,
  makeApiHandlerResponseSuccess,
} from '../utils/http-helpers';

/**
 * ***************************************************
 * GET CSV REPORT
 * * used directly in the client since AWS has a 5mb limit
 * * on downloads, so we need to call the API directly to
 * * bypass lambda limits
 * ***************************************************
 */

export interface GetBatchReportCsvFull extends HandlerReturn {
  csv: string | Blob | null;
  filename?: string | null;
}

export interface GetBatchReportCsvFullProps {
  batchReportId: string;
}

export const getBatchReportsCsv: ApiHandler<
  GetBatchReportCsvFull,
  GetBatchReportCsvFullProps
> = async (token: string, { batchReportId }) => {
  try {
    const response = await fetchWrapper(
      `${config.API_URL}/jobs/batch/${batchReportId}/export/full`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          responseType: 'blob',
          timeout: '60000',
        },
      }
    );

    const filename = response.headers.get('Content-Disposition')?.split('=')[1];

    if (response.ok) {
      const csv = await response.blob();
      return {
        ...makeApiHandlerResponseSuccess(),
        csv,
        filename,
      };
    }
    if (errorsBySourceType.BATCH_REPORT[response.status]) {
      return {
        ...makeErrorResponse({
          status: response.status,
          sourceType: 'BATCH_REPORT',
        }),
        csv: null,
      };
    } else {
      return {
        ...makeErrorResponse({
          status: response.status,
          sourceType: 'BATCH_REPORT',
        }),
        csv: null,
        message: 'BATCH_REPORT_PROCESSING_ISSUE',
      };
    }
  } catch (error) {
    return {
      ...makeApiHandlerResponseFailure(),
      csv: null,
      message: 'BATCH_REPORT_PROCESSING_ISSUE',
    };
  }
};

const BatchReport = {
  getBatchReportsCsv,
};

export default BatchReport;
