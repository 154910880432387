import { ArrowRightIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import Link from 'next/link';
import React, { FC, ReactNode } from 'react';

import { usePlaywright } from '../../hooks/usePlaywright';
import { PlaywrightProps } from '../../types/global';
import LoadingIcon from '../svgs/LoadingIcon';

export interface TabButtonProps
  extends PlaywrightProps,
    React.HTMLAttributes<HTMLButtonElement> {
  selected: boolean;
  value: ReactNode;
  label: string;
  index: number;
  totalTabHeaders?: number;
  link?: {
    href: string;
    label: string;
  };
  disabled?: boolean;
}

const TabButton: FC<TabButtonProps> = ({
  selected,
  playwrightId,
  label,
  value,
  index,
  totalTabHeaders,
  link,
  className,
  disabled,
  ...otherProps
}) => {
  const [, setPlaywrightId] = usePlaywright(playwrightId || 'tab-btn');
  return (
    <button
      type="button"
      className={classNames(
        ' w-full relative z-10 py-2 sm:py-5 px-4 bg-white text-left md:w-full border-[1px] flex flex-col justify-end shadow focus:outline-none focus:ring-0 sm:min-h-[100px]',
        className,
        selected ? 'border-new-highlight' : '',
        totalTabHeaders === 1 ? 'pointer-events-none !border-transparent' : '',
        disabled ? 'pointer-events-none opacity-50' : ''
      )}
      {...setPlaywrightId(`${label}-tab`)}
      {...otherProps}
    >
      <p className="text-lg sm:text-2xl font-medium text-new-highlight">
        {value || <LoadingIcon className="text-new-highlight mb-2 mt-1" />}
      </p>
      <p className="text-sm text-primary flex justify-between">
        <span className="block">{label}</span>
      </p>
      {link && (
        <Link href={link.href} title={label}>
          <ArrowRightIcon className="text-xs z-20  text-gray-400 flex items-center font-bold -rotate-45 absolute top-3 right-3 p-0.5 w-6 h-6 hover:bg-new-highlight rounded-full hover:text-white" />
        </Link>
      )}
    </button>
  );
};

export default TabButton;
