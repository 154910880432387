import React from 'react';
import { PlaywrightProps } from '../../types/global';

type LinkedInPost = {
  content: string;
  followers: string;
  time: string;
  playwrightId?: string;
};

const LinkedInFeed = ({
  className,
  playwrightId,
}: { className?: string } & PlaywrightProps) => {
  const [content, setContent] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    fetch('/api/linkedin')
      .then(res => res.json())
      .then(res => {
        if (loading && content.length === 0) setLoading(false);
        setContent(res);
      });
  }, []);

  return (
    <div
      {...playwrightId}
      className={`${
        className ?? ''
      } scrollbar scrollbar-thumb-gray-400  scrollbar-track-transparent `}
    >
      <ul
        className={`pb-4 space-y-4 min-h-[300px] h-full ${
          loading ? 'animate-pulse bg-gray-300 rounded-md' : ''
        } `}
      >
        {content.map((item: LinkedInPost, index) => {
          if (item)
            return (
              <li className="bg-white px-3 pb-4 rounded" key={index}>
                <div className=" flex items-center pt-2  space-x-4 w-full ">
                  <div className="relative w-16">
                    <a
                      href="https://www.linkedin.com/company/wiserfunding/"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <img
                        src="/images/logos/wf-logo-linked-in.png"
                        alt="WF LinkedIn"
                      />
                    </a>
                  </div>
                  <div className="-space-y-1">
                    <a
                      href="https://www.linkedin.com/company/wiserfunding/"
                      target="_blank"
                      className="hover:underline"
                      rel="noreferrer noopener"
                    >
                      <h4 className="font-bold text-sm">Wiserfunding</h4>{' '}
                    </a>
                    <p className="text-xs pt-0.5 pb-0.5">{item?.followers}</p>
                    <small className="block">{item?.time}</small>
                  </div>
                </div>
                <p
                  className="text-xs body-linkedin break-words"
                  dangerouslySetInnerHTML={{
                    __html: item.content,
                  }}
                ></p>
              </li>
            );
        })}
      </ul>
    </div>
  );
};

export default LinkedInFeed;
