import React from 'react';

import { Option } from '../components/elements/MultiSelect';
import {
  handleSetPortfolios as handleSetSelectedPortfolios,
  resetSelectedPortfolios,
} from '../components/modals/helpers/ModalHelpers';
import fetcher from '../lib/utils/fetcher';
import {
  handleQueryParams,
  makeUrlQueryParams,
  QueryParams,
} from '../lib/utils/http-helpers';
import { IPortfolio } from '../types/portfolio';
import useModal from './useModal';
import useSWRWithToasts from './useSWRWithToasts';
import useUser from './useUser';

export interface UsePortfolio {
  limit?: number;
  withCompanies?: boolean;
  companyUUID?: string | null;
}

const defaultProps = {
  limit: 10,
  withCompanies: false,
};

type SelectedPortfolio = {
  id: string;
  label: string;
} | null;

const useAllPortfolios = ({
  limit,
  withCompanies,
}: UsePortfolio = defaultProps) => {
  const [selectedPortfolio, setSelectedPortfolio] =
    React.useState<SelectedPortfolio>(null);
  const [selectedPortfolios, setSelectedPortfolios] = React.useState<Option[]>(
    []
  );

  const { userFeatureIsActive } = useUser();
  const modal = useModal();

  const portfoliosIsActive = userFeatureIsActive('PORTFOLIO_MANAGEMENT');

  const [query, setQuery] = React.useState<QueryParams>({
    sort: '',
    order: '',
    start: 0,
    end: limit,
    search: '',
    ...(withCompanies && { withCompanies: true }),
  });

  const handleQuery = handleQueryParams(setQuery);

  let url = `/api/portfolios?${makeUrlQueryParams(query)}`;

  const {
    data: portfolios,
    isValidating: isValidatingPortfolios,
    mutate: mutatePortfolios,
  } = useSWRWithToasts<{ portfolios: IPortfolio[]; total: number }>(
    portfoliosIsActive && !!withCompanies && url,
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  // ==============================
  // SET STATE HELPERS
  // ==============================
  const resetPortfolios = () =>
    setSelectedPortfolios(
      resetSelectedPortfolios({
        portfolios: portfolios?.data?.portfolios ?? [],
      })
    );

  React.useEffect(() => {
    const portfolioOptions: Option[] =
      portfolios?.data?.portfolios?.map(portfolio => ({
        label: portfolio.name,
        value: portfolio.id,
        selected: false,
      })) || [];

    setSelectedPortfolios(portfolioOptions);
  }, [portfolios]);

  const handleSetPortfolios = (option: Option) => {
    setSelectedPortfolios(
      handleSetSelectedPortfolios({
        option,
        selectedPortfolios,
      })
    );
  };

  const resetSelected = ({
    portfolios = true,
    singlePortfolio = true,
  }: Record<string, boolean> = {}) => {
    singlePortfolio && setSelectedPortfolio(null);
    portfolios && resetPortfolios();
  };

  return {
    query,
    handleQuery,
    portfoliosIsActive,
    // ------------------------------
    portfolios: portfolios?.data,
    isValidatingPortfolios,
    mutatePortfolios,
    // ------------------------------
    // select one portfolio
    selectedPortfolio,
    setSelectedPortfolio,
    // ------------------------------
    // select multiple portfolios
    selectedPortfolios,
    setSelectedPortfolios, // helper for setSelectedPortfolios
    handleSetPortfolios,
    // ------------------------------
    // reset
    resetSelected,
    // ------------------------------
    // modal
    modal,
  };
};

export default useAllPortfolios;
