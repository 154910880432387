/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/cognitive-complexity */
import { Session } from 'next-auth';
import { getSession, useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import useSWR from 'swr';

import { ApiHandlerResponse } from '../lib/api-handler/api-handler';
import {
  GetBatchReportCsvFull,
  getBatchReportsCsv,
} from '../lib/funcs/batch-reports';
import { isJobPending } from '../lib/utils/batch-report-helpers';
import fetcher from '../lib/utils/fetcher';
import { downloadFile } from '../lib/utils/file-helpers';
import {
  handleQueryParams,
  makeUrlQueryParams,
  QueryParams,
} from '../lib/utils/http-helpers';
import { BatchReportResponse } from '../types/batch-reports';

type UseBatchReportsHistoryOptions = {
  limit?: number;
  revalidateOnMount?: boolean;
  cancelling?: boolean;
  setCancelling?: React.Dispatch<React.SetStateAction<boolean>>;
};

const useBatchReportsHistory = ({
  limit = 10,
  revalidateOnMount = true,
  cancelling,
  setCancelling,
}: UseBatchReportsHistoryOptions = {}) => {
  const { data: session } = useSession();
  const [downloading, setDownloading] = useState(false);
  const [isRunning, setIsRunning] = useState(false);

  const [query, setQuery] = useState<QueryParams>({
    sort: '',
    order: '',
    start: 0,
    end: limit,
  });

  const handleQuery = handleQueryParams(setQuery);

  let url = `/api/batch-reports?${makeUrlQueryParams(query)}`;

  // if no user then revalidate onMount to prevent blank page
  const { data, isValidating, isLoading, mutate } = useSWR<
    ApiHandlerResponse<{
      batch_reports: BatchReportResponse[];
      total: number;
    }>
  >(url, fetcher, {
    revalidateOnFocus: false,
    revalidateOnMount: revalidateOnMount,
    refreshInterval: isRunning ? 1000 : 0,
  });

  /**
   * If cancelling, revalidate every 10 seconds.
   * I know it seems like duplication but the keys
   * had to be different else you trigger constant
   * refreshing of the table
   */
  useSWR<
    ApiHandlerResponse<{
      batch_reports: BatchReportResponse[];
      total: number;
    }>
  >(cancelling && `${url}&cancel=true`, fetcher, {
    revalidateOnFocus: false,
    revalidateOnMount: revalidateOnMount,
    refreshInterval: 2000,
    onSuccess(data, key, config) {
      if (data.data?.batch_reports?.some(report => !!report.cancelled_at)) {
        setCancelling?.(false);
      }
    },
  });

  const hasPendingBatch =
    data?.data?.batch_reports?.some(batchReport => isJobPending(batchReport)) ||
    false;

  useEffect(() => {
    setIsRunning(hasPendingBatch);
  }, [hasPendingBatch]);

  const isFetching = isLoading || !data?.data;

  const fetchedBatchReports = data?.data?.batch_reports || [];
  const totalLength = data?.data?.total || 0;

  const handleExportCsv = async (
    id: string,
    name: string,
    _session?: Session
  ) => {
    const _token = await getSession();
    const token = _token?.token || _session?.token || session?.token;

    if (!id || !token) return null;

    try {
      setDownloading(true);
      const response: GetBatchReportCsvFull = await getBatchReportsCsv(
        `${token}`,
        { batchReportId: `${id}` }
      );
      downloadFile({
        data: response.csv,
        fileName: `${name}.csv`,
        fileType: 'text/csv',
      });
    } catch (error) {
      // TODO: remove console.log
      // eslint-disable-next-line no-console
      console.log(error);
    } finally {
      setDownloading(false);
    }
  };

  return {
    batchReports: fetchedBatchReports,
    mutate,
    isValidating,
    fetching: isFetching,
    error: data?.isError,
    totalLength: Number(totalLength),
    message: data?.message,
    handleExportCsv,
    query,
    handleQuery,
    downloading,
  };
};

export default useBatchReportsHistory;
