import React, { ReactNode } from 'react';

import { PlaywrightProps } from '../../types/global';
import TabContext from './TabContext';
import Header from './TabHeader';
import Headers from './TabHeaderContainer';
import Panel from './TabPanel';
import Panels from './TabPanelContainer';
import { TabHeaderContainerProps, TabHeaderProps } from './tabs';

interface ChildComponents {
  Headers: React.FC<TabHeaderContainerProps>;
  Header: React.FC<TabHeaderProps>;
  Panels: React.FC<{ children?: ReactNode }>;
  Panel: React.FC<{ children?: ReactNode }>;
}

const Tabs: React.FC<
  PlaywrightProps & { className?: string; children?: ReactNode }
> &
  ChildComponents = ({ children, className, playwrightId }) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const value = { activeTab, setActiveTab };
  return (
    <TabContext.Provider value={value}>
      <div className={className}>{children}</div>
    </TabContext.Provider>
  );
};

Tabs.Headers = Headers;
Tabs.Header = Header;
Tabs.Panels = Panels;
Tabs.Panel = Panel;

export default Tabs;
